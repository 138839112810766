<template>
  <v-container v-if="editableTheme" :class="{ 'pa-0': inDialog }">
    <v-row  >
      <v-col cols="12" class="subheading">
        In today's world, with Instagram and Snapchat leading the way... image is everything.
        Let's make you look good!
      </v-col>
      <v-col cols="12" class="caption">
        Click a bar to change the color, click the text to change the text color.
      </v-col>
    </v-row>
    <v-row   v-for="color in colors" :key="color.display">
      <v-col cols="12">
        <v-toolbar :color="editableTheme[color.color]" @click.stop="editMe(color, 'color', $event)">
          <v-toolbar-title :style="`color: ${editableTheme[color.text]}`" @click.stop="editMe(color, 'text', $event)">
            {{color.display}}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-col>
    </v-row>
    <template v-if="editableTheme.logoUrl">
      <v-divider class="mt-4"></v-divider>
      <v-row  >
        <v-col cols="12" class="subheading">
          Sample header with your logo
        </v-col>
        <v-col cols="12">
          <v-toolbar :color="editableTheme['color1']">
            <img
              :src="editableTheme.logoUrl"
              style="height: 100%; max-height: 100%; position: relative"
            >
          </v-toolbar>
        </v-col>
      </v-row>
    </template>
    <!-- BUTTONS -->
    <v-row dense style="min-height: 76px">
      <v-col cols="12" sm="6">
        <v-fab-transition>
          <v-tooltip right>
            <span>{{activeFab.tooltip}}</span>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="color3Text color3--text"
                fab
                @click.stop="activeFab.action()"
                v-if="(theme.isPreview || previewable) && !showMenu"
              >
                <v-icon>{{activeFab.icon}}</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
        </v-fab-transition>
        <v-fab-transition>
          <v-btn
            color="color3"
            text
            @click.stop="preview"
            v-if="previewable && theme.isPreview && !showMenu"
          >
            Update Preview
          </v-btn>
        </v-fab-transition>
      </v-col>
      <v-col class="text-right" cols="12" sm="6" >
        <v-fab-transition>
          <v-btn
            v-if="dirty && !showMenu"
            color="error"
            class="mr-2"
            fab
            @click.stop="cancel"
            :loading="saving"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-fab-transition>
          <v-btn
            v-if="dirty && !showMenu"
            color="color3 color3Text--text"
            fab
            @click.stop="save"
            :loading="saving"
          >
            <v-icon>fas fa-save</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-col>
    </v-row>
    <!-- POPUP MENU -->
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      :close-on-content-click="false"
    >
      <v-card v-if="showMenu && selectedColor">
        <color-picker
          v-model="selectedColor"
          @input="updateValue"
          style="width: auto"
          :head="title"
          @ok="showMenu = false"
          @cancel="undo"
          ref="picker"
        ></color-picker>
      </v-card>

    </v-menu>
  </v-container>
</template>

<script>
import { Photoshop } from 'vue-color'
import { mapGetters } from 'vuex'
import * as mutations from '@/store/MutationTypes'
import * as actions from '@/store/ActionTypes'

export default {
  props: ['username', 'triggerLoad', 'inDialog'],
  data () {
    return {
      showMenu: false,
      selectedColorObj: null,
      selectedProp: null,
      x: 0,
      y: 0,
      loading: false,
      editableTheme: null,
      og: null,
      saving: false,
      oDto: null
    }
  },
  computed: {
    ...mapGetters([
      'theme',
      'getTheme',
      'subdomain'
    ]),
    activeFab () {
      return this.theme.isPreview ? {
        icon: 'fas fa-eye-slash',
        action: this.cancelPreview,
        tooltip: 'Cancel Preview'
      } : {
        icon: 'fas fa-eye',
        action: this.preview,
        tooltip: 'Preview Theme'
      }
    },
    colors () {
      return [
        { display: 'Primary', color: 'color1', text: 'color1Text' },
        { display: 'Secondary', color: 'color2', text: 'color2Text' },
        { display: 'Action', color: 'color3', text: 'color3Text' }
      ]
    },
    selectedColor: {
      get: function () {
        return this.selectedColorObj && this.selectedProp && this.editableTheme[this.selectedColorObj[this.selectedProp]]
      },
      set: function (val) {
        this.editableTheme[this.selectedColorObj[this.selectedProp]] = val.hex
      }
    },
    title () {
      return `${this.selectedColorObj.display} ${this.selectedProp === 'text' ? ' Text' : ' Background'}`
    },
    dirty () {
      const a = Object.assign({}, this.og)
      delete a.color6
      const b = Object.assign({}, this.editableTheme)
      delete b.color6
      return JSON.stringify(a).toLowerCase() !== JSON.stringify(b).toLowerCase()
    },
    previewable () {
      return JSON.stringify(this.editableTheme) !== JSON.stringify(this.theme)
    }
  },
  methods: {
    load (mount) {
      if (mount && this.theme.isPreview) {
        this.editableTheme = Object.assign({}, this.theme)
        this.og = Object.assign({}, this.getTheme(this.username))
        return
      }
      this.loading = true
      this.$store.dispatch(actions.LOAD_THEME, this.username)
        .then(() => {
          this.editableTheme = Object.assign({}, this.getTheme(this.username))
          this.editableTheme.isPreview = true
          this.og = Object.assign({}, this.editableTheme)
          this.loading = false
        })
    },
    preview () {
      this.editableTheme.color6 = this.editableTheme.color6 !== '#fff' ? '#fff' : '#000'
      this.$store.commit(mutations.APPLY_THEME, this.editableTheme)
    },
    cancelPreview () {
      this.$store.dispatch(actions.LOAD_THEME, this.subdomain || 'vbl')
        .then(username => {
          this.$store.commit(mutations.APPLY_THEME, username)
        })
    },
    save () {
      this.saving = true
      this.$VBL.theme.post(this.editableTheme)
        .then(response => {
          this.$store.dispatch(actions.UPDATE_THEME, response.data)
          this.cancel()
        })
        .catch(error => {
          console.log(error.response)
        })
        .finally(() => {
          this.saving = false
        })
    },
    cancel () {
      this.cancelPreview()
      this.load()
    },
    editMe (color, prop, e) {
      this.selectedColorObj = color
      this.selectedProp = prop
      this.x = e.clientX
      this.y = e.clientY
      this.showMenu = true
    },
    updateValue (val) {
      this.editableTheme[this.selectedColorObj[this.selectedProp]] = val.hex
      if (this.theme.isPreview) {
        this.preview()
      }
    },
    undo () {
      this.updateValue({ hex: this.$refs.picker.currentColor })
      this.showMenu = false
    }
  },
  watch: {
    username: function (val) {
      if (val) {
        this.load()
      }
    },
    triggerLoad: function (val) {
      if (val) {
        this.load()
      }
    },
    theme: {
      handler: function (n, o) {
        if (n && o && !n.isPreview) {
          this.load()
        }
      },
      deep: true
    },
    dirty: function (val) {
      this.$emit('dirty-change', val)
    }
  },
  components: {
    'color-picker': Photoshop
  },
  mounted () {
    this.load(true)
  },
  beforeDestroy () {
    // this.cancelPreview()
  }
}
</script>

<style>

</style>
